import "./Footer.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <div className="footer">
      <div className="logo">
        <img src={require("../../assets/img/red-logo2.png")} alt="logo" />
      </div>
      <div className="menu">
        <h3>
          <a href="#about">About</a>
        </h3>
        <h3>
          <a href="#fundraisers">Fundraisers</a>
        </h3>
        <h3>
          <a href="#team">Team</a>
        </h3>
      </div>
      <div className="term">
        <h3>Privacy policy</h3>
        <h3>Terms of use</h3>
        <h3>Support</h3>
      </div>
      <div className="contacts">
        <p>For inquiries or support please contact us:</p>
        <div className="social">
          <FacebookIcon />
          <InstagramIcon />
        </div>
        <div className="copyrights">© 2023 by Lisyk & Vira Trambovetska.</div>
      </div>
    </div>
  );
};

export default Footer;
