import "./Topbar.scss";
import MenuIcon from "@mui/icons-material/Menu";

const Topbar = () => {
  return (
    <div className="topbar">
      <div className="wrapper">
        <div className="left">
          <h2>
            <a href="#about">About</a>
          </h2>
          <h2>
            <a href="#fundraisers">Fundraisers</a>
          </h2>
          <h2>
            <a href="#team">Team</a>
          </h2>
        </div>
        <a href="#intro">
          <div className="logo">
            <img src={require("../../assets/img/red-logo2.png")} alt="logo" />
          </div>
        </a>
        <div className="right">
          <a href="#donate">Participate</a>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
