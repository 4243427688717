import "./About.scss";

import React from "react";

const About = () => {
  return (
    <div className="about">
      <h2>ABOUT</h2>
      <p>
        We are a team of Ukrainian women living in the US, thousands of
        kilometers away from our families. This year, we have looked at
        countless photos of peaceful Ukraine many times, reminiscing about our
        lives there. Over the past year we've been volunteering in various ways,
        including selling souvenirs, cooking traditional Ukrainian food, loading
        boxes of clothing and food for Ukraine, and organizing rallies.
        Honestly, sometimes we are tired as hell, but we have no right to give
        up. We have seen the wildly popular Ukrainian-style photoshoots in
        London and Paris and decided to organize a similar one in the DMV area
        to raise funds to help the people of Ukraine. The outfits and
        accessories are all made by skilled Ukrainian artisans, using
        traditional techniques and materials that have been passed down through
        generations. All the floral wreaths are handmade and we are confident
        that they will make a beautiful addition to your social media profile
        picture. We all want to thank Ukrainians and Americans, who became
        volunteers, for the incredible work you have done and continue to do in
        the midst of humanitarian disaster, often putting their lives at risk
        24/7. We want them to come back alive. We want them to have place to
        return.
      </p>
      <h3>Please help us in our efforts to help them</h3>
    </div>
  );
};

export default About;
