import "./Conditions.scss";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

import React from "react";

const Conditions = () => {
  return (
    <div className="conditions">
      <p>
        <NewReleasesIcon
          sx={{
            fontSize: 20,
            marginRight: 0.5,
            color: "#ffffff",
          }}
        />
        Pay <span>$50 or more</span> and you'll be automatically entered into
        the raffle for a chance to win a photoshoot.
      </p>
      <p>
        Pay <span>$500 or more</span> and you'll lock your spot in the
        photography session.
      </p>
      <p>
        You will have the opportunity to select <span>one look</span> for your
        photoshoot, and <span>the best three edited digital images</span> will
        be provided to you.
      </p>
      <p className="skip-after">
        Please, note all donations are non-refundable.
      </p>
      <p className="bold-white">
        Registration will be open from March 20th to April 20th.
      </p>
      <p className="bold-white">
        The 5 lucky winners will be selected by Random.org on April 21st.
      </p>
      <p className="bold-white" id="about">
        The photoshoot with winners will take place at Ukraine House on May 14th
      </p>
    </div>
  );
};

export default Conditions;
