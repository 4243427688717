import "./Details.scss";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";

const Details = () => {
  return (
    <div className="details">
      <div className="details-grid">
        <div className="grid-item item1"></div>
        <div className="grid-item item2">
          <p>
            By making a donation, you will be entered (optional) into a raffle
            for a chance to participate in a photo session showcasing stunning
            Ukrainian attire in the DMV area. The project is dedicated to
            genuine women. Strong yet vulnerable, virtuous yet flawed, not
            perfect but always honest. We believe that the mirror can reveal who
            we truly are and what is true love, friendship, support, freedom and
            unity.
          </p>
        </div>
        <div className="grid-item item3"></div>
        <div className="grid-item item4"></div>
        <div className="arrowDown" id="donate">
          <ArrowDownwardSharpIcon sx={{ fontSize: 60 }} className="arrow" />
        </div>
      </div>
    </div>
  );
};

export default Details;
