import "./Team.scss";

const team = [
  {
    id: "111",
    name: "Anna Sydorenko",
    title: "Creative director, photographer",
    img: "../../assets/img/team/anna.png",
    href: "https://www.instagram.com/sydorenkoa",
  },
  {
    id: "112",
    name: "Sasha Kordiiaka",
    title: "Strategist",
    img: "../../assets/img/team/sasha.png",
    href: "https://www.facebook.com/profile.php?id=100000505625428",
  },
  {
    id: "113",
    name: "Kate Sema",
    title: "Art director",
    img: "../../assets/img/team/kate.png",
    href: "https://www.facebook.com/kate.sema.5",
  },
  {
    id: "1133",
    name: "empty",
    title: "empty",
    img: null,
    href: null,
  },
  {
    id: "114",
    name: "Svitlana Mamoshyna",
    title: "Make up artist, hair stylist",
    img: "../../assets/img/team/svitlana.png",
    href: "http://lanared.pro",
  },
  {
    id: "116",
    name: "Vira Trambovetska",
    title: "Floral design",
    img: "../../assets/img/team/vira.png",
    href: "https://www.facebook.com/profile.php?id=100007749586465",
  },
  {
    id: "1166",
    name: "empty",
    title: "empty",
    img: null,
    href: null,
  },
  {
    id: "117",
    name: "Helen Solovey",
    title: "Floral design",
    img: "../../assets/img/team/helen.png",
    href: "https://www.facebook.com/helen.solovey",
  },
  {
    id: "118",
    name: "Mila Umakhanova",
    title: "Floral design",
    img: "../../assets/img/team/mila.png",
    href: "https://www.facebook.com/lyudmila.yaschuk",
  },
  {
    id: "120",
    name: "Oksana Sokolova",
    title: "Floral design",
    img: "../../assets/img/team/oksana.png",
    href: "https://www.facebook.com/oksana.sokolova.3576",
  },
  {
    id: "115",
    name: "Iryna Rybytva",
    title: "Accessories",
    img: "../../assets/img/team/iryna.png",
    href: "https://www.facebook.com/irynarybytva",
  },
  {
    id: "119",
    name: "Aliona Fishman",
    title: "Floral design",
    img: "../../assets/img/team/fishman.png",
    href: "https://ofishman.wixsite.com/alenasartstudio2",
  },
  {
    id: "1123",
    name: "Mariia Surdu",
    title: "Accessories",
    img: "../../assets/img/team/masha.png",
    href: "https://www.facebook.com/mariia.surdu",
  },
  {
    id: "121",
    name: "Lisyk",
    title: "Developer, video production",
    img: "../../assets/img/team/lisyk2.png",
    href: null,
  },
];

const Team = () => {
  return (
    <div className="team">
      <div className="team-title">
        <p>TEAM</p>
      </div>

      <div className="people">
        <a
          href="https://www.instagram.com/sydorenkoa"
          target="_blank"
          rel="noreferrer"
        >
          <div className="person-wrapper">
            <div className="person-pic anna"></div>
            <h5>Creative director, photographer</h5>
            <h3>Anna Sydorenko</h3>
          </div>
        </a>

        <a
          href="https://www.facebook.com/profile.php?id=100000505625428"
          target="_blank"
          rel="noreferrer"
        >
          <div className="person-wrapper">
            <div className="person-pic sasha"></div>
            <h5>Strategist</h5>
            <h3>Sasha Kordiiaka</h3>
          </div>
        </a>

        <a
          href="https://www.facebook.com/kate.sema.5"
          target="_blank"
          rel="noreferrer"
        >
          <div className="person-wrapper">
            <div className="person-pic kate"></div>
            <h5>Art director</h5>
            <h3>Kate Sema</h3>
          </div>
        </a>

        <a href="http://lanared.pro" target="_blank" rel="noreferrer">
          <div className="person-wrapper">
            <div className="person-pic sveta"></div>
            <h5>Make up artist, hair stylist</h5>
            <h3>Svitlana Mamoshyna</h3>
          </div>
        </a>

        <a
          href="https://www.facebook.com/profile.php?id=100007749586465"
          target="_blank"
          rel="noreferrer"
        >
          <div className="person-wrapper">
            <div className="person-pic vira"></div>
            <h5>Floral design, UX/UI design</h5>
            <h3>Vira Trambovetska</h3>
          </div>
        </a>

        <a
          href="https://www.facebook.com/helen.solovey"
          target="_blank"
          rel="noreferrer"
        >
          <div className="person-wrapper">
            <div className="person-pic helen"></div>
            <h5>Floral design</h5>
            <h3>Helen Solovey</h3>
          </div>
        </a>

        <a
          href="https://www.facebook.com/lyudmila.yaschuk"
          target="_blank"
          rel="noreferrer"
        >
          <div className="person-wrapper">
            <div className="person-pic mila"></div>
            <h5>Floral design</h5>
            <h3>Mila Umakhanova</h3>
          </div>
        </a>

        <div className="person-wrapper">
          <div className="person-pic oksana"></div>
          <h5>Floral design</h5>
          <h3>Oksana Sokolova</h3>
        </div>

        <a
          href="https://ofishman.wixsite.com/alenasartstudio2"
          target="_blank"
          rel="noreferrer"
        >
          <div className="person-wrapper">
            <div className="person-pic fishman"></div>
            <h5>Floral design</h5>
            <h3>Aliona Fishman</h3>
          </div>
        </a>

        <a
          href="https://www.facebook.com/irynarybytva"
          target="_blank"
          rel="noreferrer"
        >
          <div className="person-wrapper">
            <div className="person-pic ira"></div>
            <h5>Accessories</h5>
            <h3>Iryna Rybytva</h3>
          </div>
        </a>

        <a
          href="https://www.facebook.com/mariia.surdu"
          target="_blank"
          rel="noreferrer"
        >
          <div className="person-wrapper">
            <div className="person-pic masha"></div>
            <h5>Accessories</h5>
            <h3>Mariia Surdu</h3>
          </div>
        </a>

        <div className="person-wrapper">
          <div className="person-pic lisyk"></div>
          <h5>Developer, video production</h5>
          <h3>Lisyk</h3>
        </div>
      </div>
    </div>
  );
};

export default Team;
