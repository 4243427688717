import "./Payment.scss";

const Payment = () => {
  return (
    <div className="payment">
      <div className="donate">
        <div className="subject">
          <span className="sum">$50</span>
          <span className="tickets">/ 1 ticket</span>
        </div>

        <div className="donate-details">
          By donating $50 you get one raffle ticket.
        </div>
        <div className="button-block">
          <a
            href="https://donate.stripe.com/test_cN2aG107FbknfF66op"
            target="_blank"
            rel="noreferrer"
          >
            <div className="donateButton">Participate</div>
          </a>
        </div>
      </div>
      <div className="donate">
        <div className="subject">
          <span className="sum">$100</span>
          <span className="tickets">/ 2 tickets</span>
        </div>

        <div className="donate-details">
          By donating $100 you get two raffle tickets.
        </div>
        <div className="button-block">
          <a
            href="https://donate.stripe.com/test_aEUbK58Eb9cf0KcdQS"
            target="_blank"
            rel="noreferrer"
          >
            <div className="donateButton donate-red">Participate</div>
          </a>
        </div>
      </div>
      <div className="donate">
        <div className="subject">
          <span className="sum">$150</span>
          <span className="tickets">/ 3 tickets</span>
        </div>

        <div className="donate-details">
          By donating $150 you get three raffle tickets.
        </div>
        <div className="button-block">
          <a
            href="https://donate.stripe.com/test_8wMbK5g6D74778A5kn"
            target="_blank"
            rel="noreferrer"
          >
            <div className="donateButton">Participate</div>
          </a>
        </div>
      </div>
      <div className="donate">
        <div className="subject">
          <span className="sum">$500</span>
          <span className="tickets"></span>
        </div>

        <div className="donate-details">
          By donating $500 or more you skip the raffle step and get photo shoot.
        </div>
        <div className="button-block">
          <a
            href="https://buy.stripe.com/test_00g3dz9Ifewz1Og9AE"
            target="_blank"
            rel="noreferrer"
          >
            <div className="donateButton">Participate</div>
          </a>
        </div>
      </div>
      <div className="donate-any">
        <div className="subject">
          <span className="sum"></span>
          <span className="tickets"></span>
        </div>
        <div className="donate-details">
          You can donate any amount at your discretion to support our project
          without participating in the raffle.
        </div>
        <div className="button-block">
          <a
            href="https://buy.stripe.com/test_00g3dz9Ifewz1Og9AE"
            target="_blank"
            rel="noreferrer"
          >
            <div className="donateButton">Participate</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Payment;
