import "./Fundraisers.scss";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";

const Fundraisers = () => {
  return (
    <div className="fundraisers">
      <h2>FUNDRAISERS</h2>

      <div className="org">
        <p>01</p>
        <div className="logo uhu-logo"></div>
        <div className="all-text">
          <p>
            United Help Ukraine is a charitable non-profit 501(c)(3)
            organization. It was started in 2014, after the Russian attacked on
            Ukraine, by a small group of people who met at a protest in
            Washington, DC.
          </p>
          <a
            href="https://unitedhelpukraine.org/"
            target="_blank"
            rel="noreferrer"
          >
            Go to UHU site
            <ArrowDownwardSharpIcon
              sx={{
                transform: "rotate(-90deg)",
                fontSize: "18px",
                marginLeft: "8px",
                marginBottom: "-5px",
              }}
            />
          </a>
        </div>
      </div>

      <div className="org">
        <p>02</p>
        <div className="logo st-logo"></div>
        <div className="all-text">
          <p>
            St.Andrew Ukrainian Orthodox Church - a church organization since
            1949 and a charitable non-profit 501(c)(3) organization. We are
            forever grateful for the outpouring of solidarity, prayers, and
            support.
          </p>
          <a href="https://standrewuoc.org/" target="_blank" rel="noreferrer">
            Go to St.Andrew Ukrainian Orthodox Church site
            <ArrowDownwardSharpIcon
              sx={{
                transform: "rotate(-90deg)",
                fontSize: "18px",
                marginLeft: "8px",
                marginBottom: "-5px",
              }}
            />
          </a>
        </div>
      </div>

      <div className="org">
        <p>03</p>
        <div className="logo ucu-logo"></div>
        <div className="all-text">
          <p>
            United for the Children of Ukraine is a charitable non-profit
            501(c)(3) organization, providing aid to Ukrainian orphans affected
            by the war.
          </p>
          <a
            href="https://www.facebook.com/profile.php?id=100087538044410"
            target="_blank"
            rel="noreferrer"
            id="team"
          >
            Go to UFCU site
            <ArrowDownwardSharpIcon
              sx={{
                transform: "rotate(-90deg)",
                fontSize: "18px",
                marginLeft: "8px",
                marginBottom: "-5px",
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Fundraisers;
