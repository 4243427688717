import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Intro from "./components/Intro/Intro";
import Topbar from "./components/Topbar/Topbar";
import Details from "./components/Details/Details";
import Payment from "./components/Payment/Payment";
import Conditions from "./components/Conditions/Conditions";
import About from "./components/About/About";
import MainPartner from "./components/MainPartner/MainPartner";
import Fundraisers from "./components/Fundraisers/Fundraisers";
import Footer from "./components/Footer/Footer";
import Team from "./components/Team/Team";
import "./App.scss";

function App() {
  return (
    <div className="app">
      <div className="app-wrapper">
        <Topbar />
        <Intro />
        <Details />
        <Payment />
        <Conditions />
        <About />
        <MainPartner />
        <Fundraisers />
        <Team />
        <Footer />
      </div>
    </div>
  );
}

export default App;
